@import '@src/assets/scss/variables.scss';

:local(.shareCollections) {
	height: 100%;
	width: 50%;
	display: flex;
	flex-direction: column;
}


:local(.header) {
	flex-shrink: 0;
	font-family: raleway;
	//font-family: Encode Sans Semi Condensed;
	margin: 20px 15px 10px;
	display: flex;
	justify-content: space-between;
	.left {
		max-width: 45%;
		> .title,
		> .desc {
			position: relative;
			//max-width: 75%;
			[contenteditable="true"] {
				outline: none;
			}
		}
	}
	.count,
	.desc {
		margin-left: 4px;
		font-size: 15px;
	}
	.count {
		color: gray;
	}
	.desc {
		color: #adadad;
	}
	.notfound {
		text-align: center;
		font-size: 25px;
		margin-top: 50px;
	}
}

:local(.collections) {
	position: relative;
	flex: 1;
}
