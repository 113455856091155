@import '@src/assets/scss/variables.scss';

$size: $shareBarsHeight;

:local(.collectionRow) {
  position: relative;
  margin-bottom: 20px;

  .head {
    margin-bottom: 10px;

    .title {
      font-size: 17px;
      font-weight: 600;
      font-family: Helvetica, sans-serif;
      display: inline-block;
      color: gray;
    }
  }

  &[data-empty="true"] {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}

:local(.bar) {
  flex: 1;
  position: relative;
  height: $size;
  white-space: nowrap;

  &[data-empty="true"] {
    height: 30px;
  }

  > .slide {
    width: fit-content;
    height: 100%;

    &:empty:before {
      content: attr(data-empty);
      color: #3c3c3c;
      margin-top: 0px;
      display: block;
      padding-left: 20px;
      font-size: 18px;
    }
  }

  .thumbnail {
    position: relative;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
    height: 100%;
    width: $size;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-color: #282828;

    .play-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 1.2rem;
      text-align: center;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: white;
      text-align: center;
    }

    img {
      height: 100%;
    }
  }
}

:local(.leftSection) {
  flex-shrink: 0;
}

:local(.row) {
  height: $size;
  display: flex;
}
