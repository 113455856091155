:local(.maintenance) {
	position: fixed;
	height: 100%;
	width: 100%;
	padding: 60px;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	background-color: #212121;
	font-family: Raleway, sans-serif;
	z-index: 1;
}