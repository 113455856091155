#collections {
  display: flex;
  flex-direction: column;

  > .page-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .collections-list {
      flex: 1;
    }
  }

  > * > .header {
    flex-shrink: 0;
    font-family: raleway;
    margin: 5px 15px 10px;
    display: flex;
    justify-content: space-between;

    .left {
      max-width: 45%;

      > .title,
      > .desc {
        position: relative;

        [contenteditable='true'] {
          outline: none;
        }

        .floating-confirm {
          top: 0;
          left: 110%;
          padding: 0;
          min-width: 0;

          button {
            white-space: nowrap;
          }
        }
      }

      > .title {
        //.edit {
        //  position: absolute;
        //  //display: none;
        //  color: gray;
        //  left: 100%;
        //  bottom: 0;
        //  pointer: cursor;
        //  &:hover {
        //    color: white;
        //  }
        //}
        //&:hover {
        //  .edit {
        //    display: block
        //  }
        //}
      }

      > .desc {
        .floating-confirm {
          top: 0;
          left: 110%;
          padding: 0;
          min-width: 0;

          button {
            white-space: nowrap;
          }
        }
      }
    }

    .right {
      position: relative;
      align-self: flex-end;
      flex-direction: column;

      .filter-selector {
        min-width: 170px;
      }

      .bottom-buttons {
        margin-top: 14px;
        margin-bottom: 3px;

        .rights-filters {
          display: inline-block;

          .filter-selector {
            min-width: 120px;

            .selector {
              color: #afafaf;
            }
          }
        }

        .create-video-button {
          display: inline-block;
          margin-right: 5px;
          margin-left: 22px;

          button {
            padding: 10px 50px;
          }
        }

        .get-rights-bulk {
          display: inline-block;
          background: #0f9d58;
          font-weight: 700;
          border-radius: 33px;
          color: white;
          padding: 10px 50px;
          letter-spacing: 2.5px;
          font-size: 11px;
          cursor: pointer;
          border: 2px solid transparent;
          margin-left: 22px;
          transition: background-color 0.2s, color 0.2s;

          &:hover {
            color: #000;
            background-color: #fff;
          }
        }
      }
    }

    .share-overlay {
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: center;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 5;

      > div {
        max-width: 520px;
        text-align: center;
        font-family: 'Public Sans', sans-serif;
        margin: auto;
        background-color: #272727;
        padding: 30px 35px;

        .desc {
          font-size: 18px;
        }

        .link {
          word-break: break-all;
          background-color: #2e2e2e;
          border-radius: 3px;
          border: 1px solid rgba(87, 87, 87, 0.3);
          padding: 5px 9px;
          font-size: 15px;
          color: #cccccc;
          margin-top: 15px;
        }
      }
    }

    .count,
    .desc {
      margin-left: 4px;
      font-size: 15px;
    }

    .count {
      color: gray;
    }

    .desc {
      color: #adadad;
    }

    .notfound {
      text-align: center;
      font-size: 25px;
      margin-top: 50px;
    }
  }

  h1 {
    font-weight: 700;
    letter-spacing: 1.5px;
  }
}
