.floating-confirm {
  position: absolute;
  background-color: white;
  padding: 15px 20px;
  min-width: 230px;
  .question {
    white-space: nowrap;
    font-size: 14px;
    color: black;
    margin-bottom: 12px;
  }
  .confirm-button {
    .button {
      width: 100%;
      //font-size: 16px;
    }
  }
}