:local(.display) {
    flex: 1;
	display: flex;
    flex-direction: column;
    overflow: hidden;
    border-left: solid 1px #fff;
	.MuiTabs-flexContainer {
		justify-content: center !important;
		button:focus {
			outline: 0px !important;// dotted;
				/* outline: 5px auto -webkit-focus-ring-color; */
		}
	}
	.MuiTabs-indicator {
		background: #3b6ece;
	}
}

:local(.titlebar) {
	flex-shrink: 0;
	padding: 75px 7px 10px;
	font-size: 19px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

:local(.buttons) {
	button {
		font-size: 10px;
    text-transform: none;
    padding: 3px 8px;
    font-family: unset;
		&:not(.button-disabled) {
			cursor: pointer;
			
		}
	}
}