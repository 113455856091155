
.logo {
	text-align: center;
	width: 100%;
	img {
		height: 80px;
		margin: 5px auto 20px;
	}
}

.logo-navbar {
	display: flex;
	align-content: center;
	align-items: center;
}