// TODO: remove and use the FeatureDisabler component
.feature_disabled {
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  > * {
    opacity: 0.25;
  }
  &:after {
    opacity: 0;
    transition: opacity 0.15s;
    transition-delay: 0s;
    //bottom: 100%;
    content: 'Feature disabled';
    background: #2c2b2d;
    border-radius: 2px;
    color: white;
    //border: 1px solid rgba(103, 103, 103, 0.16);
    font-family: 'Public Sans', sans-serif;
    font-size: 14px;

    position: absolute;
    padding: 3px 8px;
    //bottom: 5px;
    top: 102%;
    right: 0;

    //top: 0;
    //left: 0;
    //width: 100%;
    //height: 100%;
    //text-align: center;
    //align-items: center;

    z-index: 100;
  }
  &:hover:after {
    opacity: 1;
    transition: opacity 0.15s;
    transition-delay: 0.4s;
  }
}
