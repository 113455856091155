
	:local(.titleBar) {
		cursor: pointer;
		position: relative;
		$padding-h: 10px;
		$padding-w: 30px;
		padding: $padding-h $padding-w;
		color: #313131;
		&:last-of-type {
			.element {
				//border-bottom: 1px solid;
			}
		}
		.element-background,
		.element {
			width: 100%;
			height: 100px;
			//@media only screen and (max-width: 1100px) {
			//  height: 100px;
			//}
		}
		.element-background {
			background-size: cover;
			background-position: center;
			transition: filter 0.2s;
		}
		&:hover .element-background {
			filter: brightness(120%);
		}
		.element {
			position: absolute;
			top: $padding-h;
			padding: 0 $padding-w + 20px;
			left: 0;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.title {
				color: white;
				font-size: 23px;
			}
			.buttons,
			.title {
				display: flex;
				flex-direction: column;
				justify-content: center;
				position: relative;
				> .loading {
					position: absolute;
					right: -60px;
					opacity: 0;
					transition: opacity 0.2s;
					&[data-loading="true"] {
						opacity: 1;
					}
				}
			}
		}
		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(27, 27, 27, 0.47);
		}
	}