@import '@src/assets/scss/variables.scss';

.selectCollection {
	background: #3c3c3c !important;
	color: white !important;
	width: 60%;
	padding-left: 4px;
	padding-right: 4px;
	margin-left: 7px;

	option {
		background: #3c3c3c !important;
		color: white;
		height: 35px;
	}
}

:local(.header) {
	flex-shrink: 0;
	font-family: raleway;
	margin: 20px 15px 10px;
	display: flex;
	justify-content: space-between;

	.left {
		max-width: 100%;
		display: inline-flex;
		justify-content: space-around;
		width: 100%;

		> .title,
		> .desc {
			position: relative;

			[contenteditable="true"] {
				outline: none;
			}
		}

		.MuiInput-underline:after {
			border-bottom: none !important;
		}

		.MuiNativeSelect-select {
			margin-left: 5px;
		}

		.MuiFormControl-root {
			width: 50%;
		}

		.MuiSvgIcon-root.MuiNativeSelect-icon {
			color: white;
		}
	}

	.count,
	.desc {
		margin-left: 4px;
		font-size: 15px;
	}

	.count {
		color: gray;
	}

	.desc {
		color: #adadad;
	}

	.notfound {
		text-align: center;
		font-size: 25px;
		margin-top: 50px;
	}
}

:local(.collections) {
	position: relative;

	.collectionContainer {
		max-height: 100%;
	}

	.collection-content {
		width: 90%;
		margin: 0px auto;
		height: 40%;
		display: flex;
		flex-wrap: wrap;
		box-sizing: unset;
		animation: show-collection-content 0.4s;

		.contents-list {
			display: flex;
			flex-flow: wrap;
			width: 100%;
			height: 100%;

			.collectionContent {
				height: 135px;
				width: 135px;
				position: relative;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				margin: 2%;
			}
		}

	}

	@keyframes show-collection-content {
		0% {
			opacity: 0;
			max-height: 0;
		}
		100% {
			opacity: 1;
			max-height: 500px;
		}
	}

	.share-collection-row {
		margin-bottom: 20px;

		.head {
			margin-bottom: 10px;

			.title {
				font-size: 17px;
				font-weight: 600;
				font-family: Helvetica, sans-serif;
				display: inline-block;
				color: gray;
			}
		}

		.bar {
			position: relative;
			height: $shareBarsHeight;
			white-space: nowrap;

			> .slide {
				height: 100%;

				&:empty:before {
					content: attr(data-empty);
					color: #3c3c3c;
					margin-top: 0px;
					display: block;
					padding-left: 20px;
					font-size: 18px;
				}
			}

			.thumbnail {
				position: relative;
				cursor: pointer;
				display: inline-block;
				vertical-align: top;
				margin-right: 5px;
				height: 100%;
				width: $shareBarsHeight;
				overflow: hidden;
				background-size: cover;
				background-position: center;
				background-color: #282828;

				.play-button {
					display: flex;
					flex-direction: column;
					justify-content: center;
					font-size: 1.2rem;
					text-align: center;
					z-index: 1;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					color: white;
					text-align: center;
				}

				img {
					height: 100%;
				}
			}
		}

		.bar[data-empty="true"] {
			height: 30px;
		}
	}

	.share-collection-row[data-empty="true"] {
		padding-bottom: 0px;
		margin-bottom: 0px;
	}

	.collection-content[data-empty="true"] {
		padding-bottom: 10px;
	}
}
