.dndVideoContent {
    height: 135px;
    width: 135px;
    margin: 2%;
    .collectionContent {
        width: 100%;
        height: 100%;
        cursor: grab;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        .play-button {
            height: 135px;
            display: flex;
            position: relative;
        }
        .videoContent {
            position: absolute;
        }
    }
}
