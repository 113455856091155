#collection-bar {
	flex-shrink: 0;
	position: relative;
	overflow: hidden;
	width: 100%;
	white-space: nowrap;
	padding-bottom: 4px;
	.collection-container {
		margin-right: 10px;
		display: inline-block;
	}
}
