:local(.delete) {
	position: absolute;
	right: 5px;
	bottom: 5px;	
	color: #8a8989;
	font-size: 22px;
	cursor: pointer;
	transition: color 0.2s;
	&:hover {
		color: white;
	}
}