.draggable-media {
  width: 50%;
  overflow: hidden;
  opacity: 0.72;
  transition: opacity 0.2s, padding 0.2s;
  padding: 2px;

  img {
    width: 100%;
  }

  &:hover {
    padding: 4px;
    opacity: 1;
  }

  // item-icon is in templateitem
  .item-icon {
    align-items: flex-end;
    justify-content: flex-end;

    i {
      margin-right: 10px;
      margin-bottom: 10px;
      color: rgb(255, 255, 255);
      font-size: 33px;
    }
  }
}
