@import '@src/assets/scss/_formline.scss';

:local(.missingSocial) {
	background: transparent !important;
	border: 1px solid;
	border-radius: 3px;
	color: #f3b541;
	margin-bottom: 20px;
	i {
		font-size: 18px;
		vertical-align: middle;
	}
	> i {
		margin-right: 13px;
	}
}

:local(.sharePopup) {
	z-index: 4;
	.frame {
        max-height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 850px;
		background-color: #222222;
		.left {
			background: black;
			flex: 3;
			display: flex;
			justify-content: center;
			flex-direction: column;
			img {
				width: 100%;
			}
		}
		.right {
			flex: 4;
			position: relative;
			padding: 29px 35px;
			color: white;
			.missing-social {
				font-size: 15px;
			}
			.drop-down {
				.title {
					font-size: 12px !important;
				}
				.selection {
					text-align: center;
					font-size: 16px;
					cursor: pointer;
					padding-left: 10px;
					&:hover, &[data-selected="true"] {
						color: wheat;
					}
				}
			}
			> .title {
                text-align: center;
				position: relative;
				font-size: 26px;
				font-weight: 700;
				//font-family: Raleway;
				padding-bottom: 11px;
				margin-bottom: 25px;
				border-bottom: 1px solid #3a3a3a;
			}
			.line {
				text-align: left;
				> .title {
					font-size: 15px;
				}
			}
			.subtitle {
				font-size: 15px; 
			}
			.remove {
				$color: #c2c2c2;
				color: $color;
				float: right;
				margin-top: 6px;
				&:hover {
					color: lighten($color, 10%);
				}
			}
		}

		@include formline;
	}
}