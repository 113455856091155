$padding: 10px;

:local(.dndSquareGrid) {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
}

:local(.container) {
	flex: 1;
	background-color: transparent;
	transition: background-color 0.2s;
	&[data-dragover="true"] {
		background-color: #4444441f;
	}
}

:local(.grid) {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
	&:not([data-empty="false"]) {
		&:after {
			content: attr(data-empty);
			color: #ececec;
			font-size: 22px;
			position: absolute;
			top: 0;
			padding-top: 185px;	
			height: 100%;
			width: 100%;
			text-align: center;
		}
	}
}

:local(.item) {
	padding: 7px;
	text-align: center;
	width: 120px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	cursor: pointer;
	&[data-dragging="true"] {
		img {
			border: 2px solid #f1b371ad;
		}
	}
}

:local(.itemDelete) {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	display: none;
	text-align: center;
	justify-content: center;
	flex-direction: row;
	z-index: 1;
	background-color: #0000008a;
	svg {
		margin: auto;
		font-size: 46px;
    color: #b7b7b799;
	}
	&[data-removing="true"] {
		display: flex;
	}
}

:local(.emptyItem) {
	width: 100%;
	background-color: #272727;
	padding-bottom: 100%;
}

:local(.itemForEmptyGrid) {
	background-color: transparent !important;
}