// TODO: replace all uses of this class by the FormLine component
@mixin formline {
  .line {
    position: relative;
    margin: 10px 0;
    &.disabled-line:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: gray;
      z-index: 5;
    }
    > .title {
      font-size: 13px;
      margin-bottom: 5px;
      font-weight: 600;
    }
    > .desc {
      font-size: 13px;
      color: gray;
      margin-left: 10px;
    }
    &.disabled-line {
      color: #737373;
    }
    .field {
      .input,
      .textarea {
        padding: 8px 6px;
        width: 100%;
        color: white;
      }
      .input {
        padding-top: 0;
      }
      .textarea {
        border: 2px solid;
        resize: none;
      }

      .slider-times {
        margin: 10px;
      }
      .channel {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 10px;
        > .left {
        }
      }
      p {
        color: #e4e3e3;
      }
    }
  }
}
