:local(.creatorCountriesOverlay) {
  z-index: 10; /* Higher z-index than other popups */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Darker background for better contrast */
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &[data-show="true"] {
    visibility: visible;
    opacity: 1;
  }

  .frame {
    display: inline-block;
    margin: auto;
    background-color: #222222;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    max-width: 600px;
    width: 100%;
    color: white;
    text-align: center;

    .title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .remove {
        cursor: pointer;
        font-size: 18px;
        color: #cccccc;
        &:hover {
          color: white;
        }
      }
    }

    .inputs {
      margin-bottom: 20px;
      input {
        width: 100%;
        padding: 10px;
        border: 1px solid #555;
        border-radius: 4px;
        background-color: #333;
        color: white;
        font-size: 16px;

        &::placeholder {
          color: #999;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;

      .button {
        flex: 1;
        margin: 0 5px;
        padding: 10px;
        font-size: 16px;
        cursor: pointer;
        border-radius: 4px;
        border: none;

        &.button-border {
          background-color: transparent;
          color: white;
          border: 1px solid #555;

          &:hover {
            background-color: #444;
          }
        }

        &.button-blue {
          background-color: #007bff;
          color: white;

          &:hover {
            background-color: #0056b3;
          }
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.6;
        }
      }
    }
  }

  .select {
    width: 100%;
    padding: 10px;
    border: 1px solid #555;
    border-radius: 4px;
    background-color: #333;
    color: white;
    font-size: 16px;
    margin-top: 10px;

    option {
      background-color: #333;
      color: white;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  .label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #ccc;
  }

  .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;

    input {
      margin-right: 8px;
    }
  }

}
