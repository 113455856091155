

:local(.sharePrompt) {
	z-index: 4;
	.frame {
		background-color: #222222;
		padding: 23px;
		// max-width: 400px;
		height: 80vh;
		position: relative;
		padding: 29px 35px;
		color: white;
		text-align: center;
		> .content {
			height: 50vh;
			margin: 0 auto;
			margin-bottom: 5vh;
			img{
				height: 50vh;
			}
		}
		> .title {
			text-align: left;
			position: relative;
			font-size: 26px;
			font-weight: 700;
			//font-family: Raleway;
			padding-bottom: 11px;
			margin-bottom: 25px;
			border-bottom: 1px solid #3a3a3a;
		}
		.remove {
			$color: #c2c2c2;
			color: $color;
			float: right;
			margin-top: 6px;
			&:hover {
				color: lighten($color, 10%);
			}
		}
		.buttons {
			.button {
				font-weight: 600;
				white-space: nowrap;
				width: 100%;
				padding: 11px;
				font-size: 12px;
			}
		}
	}
}