.editor-preview {
  position: relative;
  margin: 10px;
  display: flex;
  flex-direction: column;
  .video-frame-container {
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .play-icon {
      font-size: 71px;
      position: absolute;
      cursor: pointer;
    }
    .video-frame {
      background-color: black;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      video, img {
        &[data-played="false"] {
          display: none;
        }
      }
    }
  }
  .tools {
    flex-shrink: 0;
    //height: 125px;
    //@media (max-height: 900px){
      height: 38px;
    //}
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    .video-timeline {
      display: none;
      visibility: hidden;
      flex: 1;
      position: relative;
      .video-timeline-bar {
        height: 100%;
        width: 3px;
        border-radius: 5px;
        background-color: #cacaca;
        position: absolute;
        top: 0;
        left: 0;
      }
      .video-timeline-container {
        padding: 3px 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        .video-timeline-item {
          cursor: pointer;
          flex: 1;
          height: 100%;
          $color: #404040;
          background-color: $color;
          background-position: center;
          background-size: cover;
          border-right: 1px solid;
          border-left: 1px solid;
          border-color: lighten($color, 4%);
          &[data-media="true"] {
            border-right: none;
            border-left: none;
          }
          &:first-of-type {
            border-left: none;
          }
          &:last-of-type {
            border-right: none;
          }
        }
      }
    }
    .video-toolbar {
      flex-shrink: 0;
      height: 28px;
      text-align: right;
      > div {
        display: inline-block;
        height: 100%;
      }
      .sound {
      }
      .fullscreen {

      }
    }
  }
}