#subnavbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  .nav-buttons {
    margin-top: 5px;
    flex-direction: row;
    font-size: 14px;
    .nav-item {
      cursor: pointer;
      $color: white;
      color: $color;
      position: relative;
      transition: color 0.2s;
      padding: 7.5px 8px;
      text-transform: uppercase;
      &:hover {
        color: darken($color, 20%);
      }
      &.active {
        $color: #77aece;
        color: $color;
        &:before {
          content: "";
          display: block;
          position: absolute;
          height: 4px;
          top: 0;
          left: 25%;
          width: 50%;
          background-color: $color;
        }
      }
    }
  }
}