@import '@src/assets/scss/variables.scss';

:local(.page) {
  display: flex;
  flex-direction: column;
}

:local(.share) {
  flex: 1;
  display: flex;
  overflow: hidden;
  h1 {
    font-weight: 700;
    letter-spacing: 1.5px;
  }
}
