html {
  overflow: hidden;
  height: 100%;
}

html {
  touch-action: none;
}

body {
  min-height: 100%;
  overscroll-behavior-x: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.oauth-page {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #212121;
  font-family: Raleway, sans-serif;
}
.oauth-page h1 {
  color: #e5e5e5;
}
.oauth-page p {
  color: #d6d5d5;
}
.oauth-page .row-fluid {
  margin-bottom: 12px;
}
.oauth-page .button {
  margin-top: 30px;
  font-size: 16px;
  padding: 15px 40px;
}

#terms {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  padding-top: 50px;
}

.popup-overlay {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}
.popup-overlay > * {
  margin: auto;
}

.ellipsis {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.starter-template {
  padding: 3rem 1.5rem;
  text-align: center;
}

.sk-circle {
  display: inline-block;
  position: relative;
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle .sk-child-elem {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-circle .sk-circle2 .sk-child-elem {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-circle .sk-circle3 .sk-child-elem {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-circle .sk-circle4 .sk-child-elem {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-circle .sk-circle5 .sk-child-elem {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-circle .sk-circle6 .sk-child-elem {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-circle .sk-circle7 .sk-child-elem {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-circle .sk-circle8 .sk-child-elem {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-circle .sk-circle9 .sk-child-elem {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-circle .sk-circle10 .sk-child-elem {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-circle .sk-circle11 .sk-child-elem {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-circle .sk-circle12 .sk-child-elem {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
body {
  color: white;
  font-family: "Public Sans", sans-serif;
  letter-spacing: 0.7px;
  background-color: #151515;
  margin: 0;
}

.btn {
  cursor: pointer;
}

.ps__scrollbar-y-rail > .ps__scrollbar-y {
  width: 5px !important;
}

.ps__scrollbar-x-rail > .ps__scrollbar-x {
  height: 4px !important;
}

.ps__rail-y:hover > .ps__thumb-y {
  width: 6px !important;
}

.ps__rail-x:hover > .ps__thumb-x {
  height: 6px !important;
}

.ps .ps__rail-x:hover {
  background-color: transparent !important;
}

.ps__thumb-y {
  width: 6px !important;
}

.ps__thumb-x {
  height: 6px !important;
}

.ps--clicking {
  background-color: transparent !important;
}

.ps .ps__rail-y:hover {
  background-color: transparent !important;
}

.button {
  outline: none;
  padding: 14px;
  letter-spacing: 2.5px;
  font-size: 11px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 33px;
  font-family: Helvetica, sans-serif;
  transition: background-color 0.2s, color 0.2s;
}
.button.button-border {
  border: 2px solid white;
  background: transparent;
  color: white;
  transition: background-color 0.2s, color 0.2s;
}
.button.button-border:disabled {
  background-color: #888;
}
.button.button-border:hover {
  color: black;
  background-color: white;
}
.button.button-blue {
  border: 2px solid #0f4cd6;
  color: white;
  background-color: #3a76ff;
  border-color: transparent;
}
.button.button-blue:hover {
  color: black;
  background-color: white;
}
.button.button-blue.disable {
  color: grey;
  background-color: #4e4a4a !important;
}
.button.button-gray {
  background-color: #403f3f;
  letter-spacing: 1.1px;
  color: white;
}
.button.button-gray:hover {
  color: black;
  background-color: white;
}
.button.button-blue-hover:hover {
  color: white;
  background-color: #0f4cd6;
}
.button.button-red {
  border: 2px solid #bf2e2e;
  color: white;
  background-color: #bf2e2e;
  border-color: transparent;
}
.button.button-red:hover {
  color: black;
  background-color: white;
}
.button.button-red-hover:hover {
  color: white;
  background-color: #bf2e2e;
}
.button.button-green {
  border: 2px solid #228a2a;
  color: white;
  background-color: #228a2a;
  border-color: transparent;
}
.button.button-green:hover {
  color: black;
  background-color: white;
}
.button.button-green-hover:hover {
  color: white;
  background-color: #228a2a;
}
.button.button-disabled, .button.button-disabled:hover {
  pointer-events: none;
  background-color: #5b5b5b;
  color: #bcbcbc;
  opacity: 0.7;
}
.button.button-disabled:focus, .button.button-disabled:hover:focus {
  text-decoration: none;
}

.error-500,
.error-404 {
  color: #e0e0e0;
}
.error-500 h1,
.error-404 h1 {
  margin-top: 90px;
}
.error-500 p,
.error-404 p {
  margin-top: 28px;
}

.input,
.textarea {
  background: transparent;
  color: #b5b4b4;
  letter-spacing: 0.7px;
  padding: 8px 5px;
  border: none;
  border-bottom: 2px solid;
  outline: none;
  transition: border-color 0.2s;
}
.input:-webkit-autofill,
.textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #272727 inset;
  -webkit-text-fill-color: #ffd17b !important;
}
.input:focus,
.textarea:focus {
  border-color: #89c1ec !important;
}
.input[disabled],
.textarea[disabled] {
  border-color: gray;
}

.link {
  font-size: 11px;
  color: white;
}
.link:hover {
  color: rgb(224.4, 224.4, 224.4);
}

.alert {
  color: white;
  font-family: "Public Sans", sans-serif;
  border-radius: 1px;
  padding: 8px 24px;
  font-size: 14px;
  margin: 10px auto;
  border: none;
}
.alert.alert-danger {
  background-color: #c14e4c;
}
.alert.alert-success {
  background-color: #8ac771;
}
.alert.alert-warning {
  background-color: #bf6416;
}
.alert.alert-info {
  background-color: #52adda;
}

.hide-empty:empty {
  display: none;
}

.remove {
  cursor: pointer;
  transition: color 0.2s;
}

.select2-container--editor .select2-selection--single {
  outline-color: #5f5f5f;
  outline-width: thin;
  background: transparent;
  border: 1px solid #6d6d6d;
  border-radius: 1px;
  height: 40px;
}
.select2-container--editor .select2-selection--single .select2-selection__rendered {
  color: white !important;
  line-height: 37px;
  padding-left: 10px;
  padding-right: 25px;
}
.select2-container--editor .select2-selection--single .select2-selection__arrow {
  height: 40px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 25px;
}
.select2-container--editor .select2-selection--single .select2-selection__arrow b {
  border-color: #c7c7c7 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--editor.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #c7c7c7 transparent;
  border-width: 0 5px 5px 5px;
}
.select2-container--editor .select2-dropdown {
  background-color: #151515;
  border-color: #6d6d6d;
}
.select2-container--editor .select2-dropdown .select2-results__option[aria-selected=true] {
  background-color: #4a4a4a;
  color: #e2e2e2;
}
.select2-container--editor .select2-dropdown .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}
.select2-container--editor .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
  margin: 0;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.logo {
  text-align: center;
  width: 100%;
}
.logo img {
  height: 80px;
  margin: 5px auto 20px;
}

.logo-navbar {
  display: flex;
  align-content: center;
  align-items: center;
}

.feature_disabled {
  position: relative;
}
.feature_disabled:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.feature_disabled > * {
  opacity: 0.25;
}
.feature_disabled:after {
  opacity: 0;
  transition: opacity 0.15s;
  transition-delay: 0s;
  content: "Feature disabled";
  background: #2c2b2d;
  border-radius: 2px;
  color: white;
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  position: absolute;
  padding: 3px 8px;
  top: 102%;
  right: 0;
  z-index: 100;
}
.feature_disabled:hover:after {
  opacity: 1;
  transition: opacity 0.15s;
  transition-delay: 0.4s;
}

#collections {
  display: flex;
  flex-direction: column;
}
#collections > .page-content {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
#collections > .page-content .collections-list {
  flex: 1;
}
#collections > * > .header {
  flex-shrink: 0;
  font-family: raleway;
  margin: 5px 15px 10px;
  display: flex;
  justify-content: space-between;
}
#collections > * > .header .left {
  max-width: 45%;
}
#collections > * > .header .left > .title,
#collections > * > .header .left > .desc {
  position: relative;
}
#collections > * > .header .left > .title [contenteditable=true],
#collections > * > .header .left > .desc [contenteditable=true] {
  outline: none;
}
#collections > * > .header .left > .title .floating-confirm,
#collections > * > .header .left > .desc .floating-confirm {
  top: 0;
  left: 110%;
  padding: 0;
  min-width: 0;
}
#collections > * > .header .left > .title .floating-confirm button,
#collections > * > .header .left > .desc .floating-confirm button {
  white-space: nowrap;
}
#collections > * > .header .left > .desc .floating-confirm {
  top: 0;
  left: 110%;
  padding: 0;
  min-width: 0;
}
#collections > * > .header .left > .desc .floating-confirm button {
  white-space: nowrap;
}
#collections > * > .header .right {
  position: relative;
  align-self: flex-end;
  flex-direction: column;
}
#collections > * > .header .right .filter-selector {
  min-width: 170px;
}
#collections > * > .header .right .bottom-buttons {
  margin-top: 14px;
  margin-bottom: 3px;
}
#collections > * > .header .right .bottom-buttons .rights-filters {
  display: inline-block;
}
#collections > * > .header .right .bottom-buttons .rights-filters .filter-selector {
  min-width: 120px;
}
#collections > * > .header .right .bottom-buttons .rights-filters .filter-selector .selector {
  color: #afafaf;
}
#collections > * > .header .right .bottom-buttons .create-video-button {
  display: inline-block;
  margin-right: 5px;
  margin-left: 22px;
}
#collections > * > .header .right .bottom-buttons .create-video-button button {
  padding: 10px 50px;
}
#collections > * > .header .right .bottom-buttons .get-rights-bulk {
  display: inline-block;
  background: #0f9d58;
  font-weight: 700;
  border-radius: 33px;
  color: white;
  padding: 10px 50px;
  letter-spacing: 2.5px;
  font-size: 11px;
  cursor: pointer;
  border: 2px solid transparent;
  margin-left: 22px;
  transition: background-color 0.2s, color 0.2s;
}
#collections > * > .header .right .bottom-buttons .get-rights-bulk:hover {
  color: #000;
  background-color: #fff;
}
#collections > * > .header .share-overlay {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
}
#collections > * > .header .share-overlay > div {
  max-width: 520px;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  margin: auto;
  background-color: #272727;
  padding: 30px 35px;
}
#collections > * > .header .share-overlay > div .desc {
  font-size: 18px;
}
#collections > * > .header .share-overlay > div .link {
  word-break: break-all;
  background-color: #2e2e2e;
  border-radius: 3px;
  border: 1px solid rgba(87, 87, 87, 0.3);
  padding: 5px 9px;
  font-size: 15px;
  color: #cccccc;
  margin-top: 15px;
}
#collections > * > .header .count,
#collections > * > .header .desc {
  margin-left: 4px;
  font-size: 15px;
}
#collections > * > .header .count {
  color: gray;
}
#collections > * > .header .desc {
  color: #adadad;
}
#collections > * > .header .notfound {
  text-align: center;
  font-size: 25px;
  margin-top: 50px;
}
#collections h1 {
  font-weight: 700;
  letter-spacing: 1.5px;
}

#adalong-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #151515;
  z-index: 999999999;
}

#subnavbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
}
#subnavbar .nav-buttons {
  margin-top: 5px;
  flex-direction: row;
  font-size: 14px;
}
#subnavbar .nav-buttons .nav-item {
  cursor: pointer;
  color: white;
  position: relative;
  transition: color 0.2s;
  padding: 7.5px 8px;
  text-transform: uppercase;
}
#subnavbar .nav-buttons .nav-item:hover {
  color: #cccccc;
}
#subnavbar .nav-buttons .nav-item.active {
  color: #77aece;
}
#subnavbar .nav-buttons .nav-item.active:before {
  content: "";
  display: block;
  position: absolute;
  height: 4px;
  top: 0;
  left: 25%;
  width: 50%;
  background-color: #77aece;
}

#collection-bar {
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  padding-bottom: 4px;
}
#collection-bar .collection-container {
  margin-right: 10px;
  display: inline-block;
}

.floating-confirm {
  position: absolute;
  background-color: white;
  padding: 15px 20px;
  min-width: 230px;
}
.floating-confirm .question {
  white-space: nowrap;
  font-size: 14px;
  color: black;
  margin-bottom: 12px;
}
.floating-confirm .confirm-button .button {
  width: 100%;
}

.collection {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.draggable-media {
  width: 50%;
  overflow: hidden;
  opacity: 0.72;
  transition: opacity 0.2s, padding 0.2s;
  padding: 2px;
}
.draggable-media img {
  width: 100%;
}
.draggable-media:hover {
  padding: 4px;
  opacity: 1;
}
.draggable-media .item-icon {
  align-items: flex-end;
  justify-content: flex-end;
}
.draggable-media .item-icon i {
  margin-right: 10px;
  margin-bottom: 10px;
  color: rgb(255, 255, 255);
  font-size: 33px;
}

.template-item {
  width: 156px;
  height: 156px;
  display: inline-block;
  position: relative;
  padding: 10px;
  transition: padding 0.1s;
  vertical-align: middle;
}
@media (max-height: 900px) {
  .template-item {
    width: 120px;
    height: 120px;
  }
}
.template-item[data-over=true] {
  padding: 13px;
}
.template-item[data-over=true] .template-item-container {
  border-width: 3px;
}
.template-item .template-item-container {
  border-radius: 1px;
  overflow: hidden;
  position: relative;
  transition: border-width 0.1s;
  border: 0px solid rgba(54, 90, 120, 0.73);
  pointer-events: none;
  background-color: #404040;
  height: 100%;
  width: 100%;
}
.template-item .template-item-container > div[data-hide=true] {
  display: none;
}
.template-item .template-item-container .item-media {
  position: relative;
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.item-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  pointer-events: none;
}
.item-icon i {
  font-size: 50px;
  color: rgba(255, 255, 255, 0.1803921569);
}

.editor-preview {
  position: relative;
  margin: 10px;
  display: flex;
  flex-direction: column;
}
.editor-preview .video-frame-container {
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.editor-preview .video-frame-container .play-icon {
  font-size: 71px;
  position: absolute;
  cursor: pointer;
}
.editor-preview .video-frame-container .video-frame {
  background-color: black;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.editor-preview .video-frame-container .video-frame video[data-played=false], .editor-preview .video-frame-container .video-frame img[data-played=false] {
  display: none;
}
.editor-preview .tools {
  flex-shrink: 0;
  height: 38px;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.editor-preview .tools .video-timeline {
  display: none;
  visibility: hidden;
  flex: 1;
  position: relative;
}
.editor-preview .tools .video-timeline .video-timeline-bar {
  height: 100%;
  width: 3px;
  border-radius: 5px;
  background-color: #cacaca;
  position: absolute;
  top: 0;
  left: 0;
}
.editor-preview .tools .video-timeline .video-timeline-container {
  padding: 3px 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.editor-preview .tools .video-timeline .video-timeline-container .video-timeline-item {
  cursor: pointer;
  flex: 1;
  height: 100%;
  background-color: #404040;
  background-position: center;
  background-size: cover;
  border-right: 1px solid;
  border-left: 1px solid;
  border-color: rgb(74.2, 74.2, 74.2);
}
.editor-preview .tools .video-timeline .video-timeline-container .video-timeline-item[data-media=true] {
  border-right: none;
  border-left: none;
}
.editor-preview .tools .video-timeline .video-timeline-container .video-timeline-item:first-of-type {
  border-left: none;
}
.editor-preview .tools .video-timeline .video-timeline-container .video-timeline-item:last-of-type {
  border-right: none;
}
.editor-preview .tools .video-toolbar {
  flex-shrink: 0;
  height: 28px;
  text-align: right;
}
.editor-preview .tools .video-toolbar > div {
  display: inline-block;
  height: 100%;
}
.video-toolbar .volume-mute,
.video-toolbar .fullscreen {
  font-size: 18px;
  color: #b3b3b3;
}
.video-toolbar .volume > * {
  vertical-align: middle;
  cursor: pointer;
}
.video-toolbar .volume .volume-bar-container {
  margin: 0 10px;
  display: inline-block;
  vertical-align: middle;
  width: 85px;
  height: 8px;
}
.video-toolbar .volume .volume-bar-container .volume-bar {
  margin-top: 2px;
  width: 100%;
  height: 4px;
  background-color: #404040;
  border-radius: 2px;
  overflow: hidden;
}
.video-toolbar .volume .volume-bar-container .volume-bar > div {
  height: 100%;
  background-color: #b3b3b3;
}
.video-toolbar .fullscreen {
  font-size: 21px;
  vertical-align: middle;
  cursor: pointer;
}