@import '@src/assets/scss/variables.scss';

:local(.settingsContainer) {
  z-index: 5;

  :local(.settings) {
    overflow: hidden;
    position: relative;
    background: #151515;
    width: 800px;
    padding: 32px 40px;
    display: flex;
    flex-direction: column;

    :local(.crossButton) {
      top: 40px !important;
      right: 40px !important;
    }

    .title {
      font-size: 20px;
      font-weight: 700;
    }

    .desc {
      padding: 0 10px;
      margin-top: 10px;
      margin-bottom: 20px;
      color: #cccccc;
    }

    #oauth {
      color: white;

      .buttons {
        margin-top: 40px;
        margin-bottom: 10px;
        width: 325px;
        margin-left: 200px;

        .input-social {
          margin-bottom: 15px;

          > * {
            display: inline-block;
          }

          .input {
            margin-right: 20px;
            margin-bottom: 10px !important;
          }

          .input.challenge-code {
            display: block;
            margin: auto;
          }

          button {
            display: block;
            margin: 0 auto;
            width: auto;
            background-color: #3a76ff !important;
            padding: 7px 25px;

            &:hover {
              background-color: white !important;
              color: black;
            }

          }

          .loader {
            display: block;
            margin: 10px auto 0;
          }
        }
      }
    }

    #mention {
      margin-bottom: 25px;

      .pages {
        position: relative;
        overflow: hidden;
        margin: 10px 70px;
        border: 1px solid #333;
        background-color: #191919;
        height: 130px;

        &:not([data-emptytext='false']):before {
          display: block;
          content: attr(data-emptytext);
          text-align: center;
          margin: auto;
          margin-top: 45px;
          color: gray;
        }

        .page {
          cursor: pointer;
          color: #8c8c8c;
          padding: 5px 14px;
          position: relative;

          &[data-selected='true'] {
            background-color: #3a3a3a21;

            > span {
              color: white;
            }
          }

          > span {
            display: inline-block;
            max-width: 490px;
          }

          :local(.loading) {
            float: right;
            margin-right: 15px;
          }

          .page-selectors {
            position: relative;
            cursor: default;
            float: right;
            margin-right: 2px;

            > div {
              cursor: pointer;
              display: inline-block;
              padding: 0 6px;
              transition: color 0.2s;

              &:hover {
                color: lighten(#8c8c8c, 18%);
              }

              &[data-selected='true'] {
                color: white !important;
              }
            }

            &[data-selecting='true'] {
              pointer-events: none;
              filter: contrast(14%) blur(0.5px);
            }
          }
        }
      }
    }

    #influencers, #blacklist {
      margin-bottom: 25px;

      .influencer-list {
        position: relative;
        overflow: hidden;
        margin: 10px 70px;
        border: 1px solid #333;
        background-color: #191919;
        height: 130px;

        .influencer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          color: #8c8c8c;
          padding: 5px 14px;
          position: relative;

          &-info {
            width: 35%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
