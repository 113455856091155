html {
  overflow: hidden;
  height: 100%;
}
html { touch-action:none; }

body {
  min-height: 100%;
  overscroll-behavior-x: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.oauth-page {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #212121;
  font-family: Raleway, sans-serif;
  h1 {
    color: #e5e5e5;
  }
  p {
    color: #d6d5d5;
  }
  .row-fluid {
    margin-bottom: 12px;
  }
  .button {
    margin-top: 30px;
    font-size: 16px;
    padding: 15px 40px;
  }
}

#terms {
  //background-color: white;
  //color: black;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  padding-top: 50px;
}

.popup-overlay {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  > * {
    margin: auto;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
