@import '@src/assets/scss/variables.scss';

body {
  color: white;
  font-family: 'Public Sans', sans-serif;
  letter-spacing: 0.7px;
  background-color: #151515;
  margin: 0;
}

.btn {
  cursor: pointer;
}

.ps__scrollbar-y-rail > .ps__scrollbar-y {
  width: 5px !important;
}

.ps__scrollbar-x-rail > .ps__scrollbar-x {
  height: 4px !important;
}

.ps__rail-y:hover > .ps__thumb-y {
  width: 6px !important;
}

.ps__rail-x:hover > .ps__thumb-x {
  height: 6px !important;
}

.ps .ps__rail-x:hover {
  background-color: transparent !important;
}

.ps__thumb-y {
  width: 6px !important;
}

.ps__thumb-x {
  height: 6px !important;
}

.ps--clicking {
  background-color: transparent !important;
}

.ps .ps__rail-y:hover {
  background-color: transparent !important;
}

// DEPRECATED: Use the Button component instead
.button {
  outline: none;
  padding: 14px;
  letter-spacing: 2.5px;
  font-size: 11px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 33px;
  font-family: Helvetica, sans-serif;
  transition: background-color 0.2s, color 0.2s;

  &.button-border {
    border: 2px solid white;
    background: transparent;
    color: white;
    transition: background-color 0.2s, color 0.2s;

    &:disabled {
      background-color: #888;
    }

    &:hover {
      color: black;
      background-color: white;
    }
  }

  &.button-blue {
    border: 2px solid #0f4cd6;
    color: white;
    background-color: $color-buttons;
    border-color: transparent;

    &:hover {
      color: black;
      background-color: white;
    }

    &.disable {
      color: grey;
      background-color: #4e4a4a !important;
    }
  }

  &.button-gray {
    background-color: #403f3f;
    letter-spacing: 1.1px;
    color: white;

    &:hover {
      color: black;
      background-color: white;
    }
  }

  &.button-blue-hover:hover {
    color: white;
    background-color: #0f4cd6;
  }

  &.button-red {
    border: 2px solid #bf2e2e;
    color: white;
    background-color: #bf2e2e;
    border-color: transparent;

    &:hover {
      color: black;
      background-color: white;
    }
  }

  &.button-red-hover:hover {
    color: white;
    background-color: #bf2e2e;
  }

  &.button-green {
    border: 2px solid #228a2a;
    color: white;
    background-color: #228a2a;
    border-color: transparent;

    &:hover {
      color: black;
      background-color: white;
    }
  }

  &.button-green-hover:hover {
    color: white;
    background-color: #228a2a;
  }

  &.button-disabled,
  &.button-disabled:hover {
    pointer-events: none;
    background-color: #5b5b5b;
    color: #bcbcbc;
    opacity: 0.7;

    &:focus {
      text-decoration: none;
    }
  }
}

.error-500,
.error-404 {
  color: #e0e0e0;

  h1 {
    margin-top: 90px;
  }

  p {
    margin-top: 28px;
  }
}

.input,
.textarea {
  background: transparent;
  color: #b5b4b4;
  letter-spacing: 0.7px;
  padding: 8px 5px;
  border: none;
  border-bottom: 2px solid;
  outline: none;
  transition: border-color 0.2s;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #272727 inset;
    -webkit-text-fill-color: #ffd17b !important;
  }

  &:focus {
    border-color: #89c1ec !important;
  }

  &[disabled] {
    border-color: gray;
  }
}

.link {
  font-size: 11px;
  color: white;

  &:hover {
    color: darken(white, 12%);
  }
}

.alert {
  color: white;
  font-family: 'Public Sans', sans-serif;
  border-radius: 1px;
  padding: 8px 24px;
  font-size: 14px;
  margin: 10px auto;
  border: none;

  &.alert-danger {
    background-color: #c14e4c;
  }

  &.alert-success {
    background-color: #8ac771;
  }

  &.alert-warning {
    background-color: #bf6416;
  }

  &.alert-info {
    background-color: #52adda;
  }
}

.hide-empty:empty {
  display: none;
}

.remove {
  cursor: pointer;
  transition: color 0.2s;
}

.select2-container--editor {
  $arrow-color: #c7c7c7;
  $arrow-size: 5px;
  $height: 40px;
  $border-color: #6d6d6d;
  $arrow-width: 25px;

  .select2-selection--single {
    outline-color: #5f5f5f;
    outline-width: thin;
    background: transparent;
    border: 1px solid $border-color;
    border-radius: 1px;
    height: $height;

    .select2-selection__rendered {
      color: white !important;
      line-height: 37px;
      padding-left: 10px;
      padding-right: $arrow-width;
    }

    .select2-selection__arrow {
      height: $height;
      position: absolute;
      top: 1px;
      right: 1px;
      width: $arrow-width;

      b {
        border-color: $arrow-color transparent transparent transparent;
        border-style: solid;
        border-width: $arrow-size $arrow-size 0 $arrow-size;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
      }
    }
  }

  &.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent $arrow-color transparent;
    border-width: 0 $arrow-size $arrow-size $arrow-size;
  }

  .select2-dropdown {
    background-color: #151515;
    border-color: $border-color;

    .select2-results__option[aria-selected='true'] {
      background-color: #4a4a4a;
      color: #e2e2e2;
    }

    .select2-results__option--highlighted[aria-selected] {
      background-color: #5897fb;
      color: white;
    }
  }

  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }
}

// Bootstrap style
// As we removed this package, some rules need
// to be set here to avoid broken style in the app
*,
::after,
::before {
  box-sizing: border-box;
}

body {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
  margin: 0;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
