/* perfect-scrollbar v0.7.1 */
.ps {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none; }
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important; } }
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important; } }
.ps.ps--active-x > .ps__scrollbar-x-rail,
.ps.ps--active-y > .ps__scrollbar-y-rail {
  display: block;
  background-color: transparent; }
.ps.ps--in-scrolling.ps--x > .ps__scrollbar-x-rail {
  //background-color: #eee;
  opacity: 0.9; }
.ps.ps--in-scrolling.ps--x > .ps__scrollbar-x-rail > .ps__scrollbar-x {
  background-color: #999;
  height: 11px; }
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail {
  //background-color: #eee;
  opacity: 0.9; }
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y {
  //background-color: #999;
  width: 11px; }
.ps > .ps__scrollbar-x-rail {
  display: none;
  position: absolute;
  /* please don't change 'position' */
  opacity: 0;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  -o-transition: background-color .2s linear, opacity .2s linear;
  -moz-transition: background-color .2s linear, opacity .2s linear;
  transition: background-color .2s linear, opacity .2s linear;
  bottom: 0px;
  /* there must be 'bottom' for ps__scrollbar-x-rail */
  height: 15px; }
.ps > .ps__scrollbar-x-rail > .ps__scrollbar-x {
  position: absolute;
  /* please don't change 'position' */
  background-color: #aaa;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
  -o-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
  -moz-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -webkit-border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
  bottom: 2px;
  /* there must be 'bottom' for ps__scrollbar-x */
  height: 6px; }
.ps > .ps__scrollbar-x-rail:hover > .ps__scrollbar-x, .ps > .ps__scrollbar-x-rail:active > .ps__scrollbar-x {
  height: 6px; }
.ps > .ps__scrollbar-y-rail {
  display: none;
  position: absolute;
  /* please don't change 'position' */
  opacity: 0;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  -o-transition: background-color .2s linear, opacity .2s linear;
  -moz-transition: background-color .2s linear, opacity .2s linear;
  transition: background-color .2s linear, opacity .2s linear;
  right: 0;
  /* there must be 'right' for ps__scrollbar-y-rail */
  width: 15px; }
.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y {
  position: absolute;
  /* please don't change 'position' */
  background-color: #aaa;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
  -o-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
  -moz-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -webkit-border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
  right: 2px;
  /* there must be 'right' for ps__scrollbar-y */
  width: 6px; }
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y, .ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y {
  width: 11px; }
.ps:hover.ps--in-scrolling.ps--x > .ps__scrollbar-x-rail {
  //background-color: #eee;
  opacity: 0.9; }
.ps:hover.ps--in-scrolling.ps--x > .ps__scrollbar-x-rail > .ps__scrollbar-x {
  background-color: #999;
  height: 11px; }
.ps:hover.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail {
  //background-color: #eee;
  opacity: 0.9; }
.ps:hover.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y {
  //background-color: #999;
  width: 11px; }
.ps:hover > .ps__scrollbar-x-rail,
.ps:hover > .ps__scrollbar-y-rail {
  opacity: 0.6; }
.ps:hover > .ps__scrollbar-x-rail:hover {
  //background-color: #7866ee;
  opacity: 0.9; }
.ps:hover > .ps__scrollbar-x-rail:hover > .ps__scrollbar-x {
  background-color: #999;
}
.ps:hover > .ps__scrollbar-y-rail:hover {
  //background-color: #eee;
  opacity: 0.9; }
.ps:hover > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  //background-color: #999;
}
