@import '@src/assets/scss/variables.scss';

:local(.prompt) {
  z-index: 5;
}

:local(.frame) {
  background-color: #272727;
  padding: 23px;
  max-width: 400px;
}

:local(.question) {
  color: #d6d6d6;
  padding: 2px 15px 18px;
  text-align: center;
  font-size: 18px;
  min-width: 280px;
}
:local(.answers) {
  display: flex;
  justify-content: space-around;
  margin-top: 14px;
  flex-direction: row;
  .button {
    min-width: 100px;
  }
}
