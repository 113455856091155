:local(.grid) {
	// flex: 1;
	height: 100%;
	padding: 10px 10px 10px;
	overflow: hidden;
	position: relative;
}

:local(.item) {
	height: 0;
	width: 100%;
	padding-bottom: 100%;
	position: relative;
}

:local(.imageContainer) {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

:local(.image) {
	height: 100%;
	width: 100%;
	background-size: cover;
	background-position: center;
	background-color: #282828;
}

:local(.delete) {
	position: absolute;
	right: 5px;
	bottom: 5px;
	color: #8a8989;
	font-size: 22px;
	cursor: pointer;
	transition: color 0.2s;

	&:hover {
		color: white;
	}
}

:local(.date) {
	font-size: x-small;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	color: white;
	padding: 9px 0 0 8px;
	text-align: left;

	svg {
		font-size: 14px !important;
	}

	span {
		vertical-align: middle;
		margin-left: 4px;
	}
}

:local(.warning) {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0000006e;
	z-index: 2;
}

:local(.warningIcon) {
	position: absolute;
	right: 5px;
	top: 5px;
}
