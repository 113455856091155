:local(.createCollection) {
	.right {
		min-width: 430px;
		.inputs {
			margin-top: 20px;
			.input {
				margin-bottom: 20px;
				width: 100%;
			}
		}
		.buttons {
			margin-top: 35px;
			button {
				width: 100%;
			}
		}
	}
}