:local(.instagramwithbuffer) {
  #comment {
    vertical-align: middle;
    align-self: center;
  }
  .creator {
    border: 2px solid #464646;
    color: #f8f9fa;
    background-color: #464646;
    border-color: transparent;
    font-family: 'Public Sans, sans-serif';
    padding: 1px;
    font-size: 12px;
    letter-spacing: 0.7px;
    align-self: center;
    text-align-last: right;
    margin-left: auto;
  }
  .flex-container {
    display: flex;
    margin-bottom: 10px;
  }
  .icon {
    font-size: 15px;
  }
}

:local(.datepicker) {
  margin-bottom: 25px;
  display: flex;
}
