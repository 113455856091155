@import '@src/assets/scss/variables.scss';

:local(.collectionContent) {
  padding: 30px 62px;

  animation: show-collection-content 0.4s;

  &[data-empty="true"] {
    padding-bottom: 10px;
  }
}

@keyframes show-collection-content {
  0% {
    opacity: 0;
    max-height: 0;
  }
  100% {
    opacity: 1;
    max-height: 500px;
  }
}

:local(.uploadButton) {
  flex-shrink: 0;
}
