@import '@src/assets/scss/variables.scss';

:local(.upload) {
	position: relative;
	cursor: pointer;
	display: inline-flex;
	vertical-align: top;
	margin: 2%;
	height: 135px;
	width: $shareBarsHeight;
	background-color: #282828;
	justify-content: space-around;
	flex-direction: column;
	transition: filter 0.15s linear;
	width: 135px;
	&:hover {
		// filter: contrast(20%)
		filter: brightness(120%);
	}
	> div {
		display: block;
		text-align: center;
		> span {
			font-size: 64px;
			color: #c1c1c1;
		}
	}
	input {
		display: none;
	}
}

:local(.icon) {
	transform: scale(4.5);
	fill: #8e8e8e !important;
}