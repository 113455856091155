@import '@src/assets/scss/variables.scss';

:local(.filters) {
  font-size: $font-size-normal;
  overflow: hidden;
  position: relative;
  > .filters-container {
    padding: 5px 15px;
  }
  .category {
    padding: 20px 0 12px;
    &:first-of-type {
      padding-top: 0;
    }
    &:not(.noborder) {
      border-bottom: 1px solid #333333;
    }
    .title {
      color: white;
      font-weight: 500;
    }
    .line {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      > .label {

      }
      > .action {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      .slider {
        width: 100%;
      }
    }
    .line:not(.title) {
      font-family: sans-serif;
    }
  }
  .checkbox {
    height: 20px;
  }

  .ps__scrollbar-y-rail >.ps__scrollbar-y {
    width: 3px !important;
  }
}