:local(.delete) {	
	color: #8a8989;
	font-size: 22px;
	cursor: pointer;
	transition: color 0.2s;
	&:hover {
		color: white;
	}
}

:local(.collectionContent) {
	width: 100%;
	height: auto;
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}