.template-item {
  $size: 156px;
  $anim: 0.1s;

  width: $size;
  height: $size;
  display: inline-block;
  position: relative;
  padding: 10px;
  transition: padding $anim;
  vertical-align: middle;

  @media (max-height: 900px) {
    $size: 120px;
    width: $size;
    height: $size;
  }

  &[data-over="true"] {
    padding: 13px;

    .template-item-container {
      border-width: 3px;
    }
  }

  .template-item-container {
    border-radius: 1px;
    overflow: hidden;
    position: relative;
    transition: border-width $anim;
    border: 0px solid rgba(54, 90, 120, 0.73);
    pointer-events: none;
    background-color: #404040;
    height: 100%;
    width: 100%;

    > div {
      &[data-hide="true"] {
        display: none;
      }
    }

    .item-media {
      position: relative;
      height: 100%;
      width: 100%;
      background-position: center;
      background-size: cover;
    }
  }
}

.item-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  pointer-events: none;

  i {
    font-size: 50px;
    color: #ffffff2e;
  }
}
