.video-toolbar {

  .volume-mute,
  .fullscreen {
    font-size: 18px;
    color: #b3b3b3;
  }

  .volume {
    >* {
      vertical-align: middle;
      cursor: pointer;
    }

    $bar-height: 4px;

    .volume-bar-container {
      margin: 0 10px;
      display: inline-block;
      vertical-align: middle;
      width: 85px;
      height: $bar-height * 2;

      .volume-bar {
        margin-top: calc($bar-height / 2);
        width: 100%;
        height: $bar-height;
        background-color: #404040;
        border-radius: 2px;
        overflow: hidden;

        >div {
          height: 100%;
          background-color: #b3b3b3;
        }
      }
    }
  }

  .fullscreen {
    font-size: 21px;
    vertical-align: middle;
    cursor: pointer;
  }
}