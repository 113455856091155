
:local(.overlay) {
	z-index: 2;
	width: 100%;
	height: 100%;
	background: #00000099;
	opacity: 0;
	transition: opacity 0.2s;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	text-align: end;
	i, img {
		$s: 40px;
		width: $s;
		height: $s;
		font-size: $s;
		margin-left: 10px;
		filter: brightness(70%);
		vertical-align: middle;
		&:hover {
			filter: brightness(100%);
		}
	}
	&:hover:not(:active) {
		opacity: 1;
	}
}