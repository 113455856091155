
:local(.overlay) {
	z-index: 2;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #8888887a;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	text-align: center;
}

:local(.progress) {
	color: #e1e2e2 !important;
}