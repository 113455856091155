:local(.display) {
	position: relative;
	width: 50%;
	display: flex;
	flex-direction: column;
	padding: 0px 20px 0px 10px;
}

:local(.titlebar) {
	flex-shrink: 0;
	padding: 75px 7px 10px;
	font-size: 19px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}