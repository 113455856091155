.loader-centered{
	position: absolute;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

:local(.collectOverlay) {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.11);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  flex-direction: column;
	justify-content: center;
	
	.frame {
		display: inline-block;
    margin: auto;
	}

  &[data-show="true"] {
    visibility: visible;
    opacity: 1;
  }

  .collect-frame__loading{
	filter: blur(5px);
	pointer-events: none;
	-webkit-filter: blur(5px);
	-moz-filter: blur(5px);
	-o-filter: blur(5px);
	-ms-filter: blur(5px);
}

  .collect-frame {
    display: flex;
    width: 850px;
    position: relative;
    margin: auto;
	background-color: #222222;
	
    .left {
      background: black;
      flex: 3;
      display: flex;
      justify-content: center;
      flex-direction: column;
      img {
        width: 100%;
      }
    }
    .right {
      min-width: 430px;
      flex: 4;
      position: relative;
      padding: 29px 35px;
      color: white;
      text-align: center;
      .title {
        font-size: 26px;
        font-weight: 700;
        //font-family: Raleway;
      }
      .desc {
        margin-top: 10px;
        margin-bottom: 20px;
        color: #cccccc;
      }
      .remove {
        $color: #c2c2c2;
        color: $color;
        float: right;
        margin-top: 6px;
        &:hover {
          color: lighten($color, 10%);
        }
      }
    }
  }
  
  #get-rights {
    .inputs {
      margin-bottom: 40px;
      margin-top: 20px;
      textarea {
        width: 400px;
        height: 135px;
        resize: none;
        overflow: hidden;
      }
    }
  }
  //#get-rights-frame {
  //  .example {
  //    text-align: left;
  //    background-color: #2e2e2e;
  //    border-radius: 3px;
  //    border: 1px solid rgba(87, 87, 87, 0.3);
  //    padding: 5px 9px;
  //    font-size: 15px;
  //    color: #cccccc;
  //    margin-top: 15px;
  //  }
  //  .buttons {
  //    margin-top: 50px;
  //    text-align: center;
  //    .button {
  //      min-width: 170px;
  //      margin: auto;
  //    }
  //  }
  //}
}